<template>
  <div>
    <!-- card form -->
    <v-card>
      <v-card-title> Relatorios </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="getBOtoUsersSelect()"
              outlined
              label="Ordem(s) para usuario"
              clearable
              v-model="report.order"
              @change="getParamsByOrder"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="devicesSelect"
              outlined
              label="Dispositivos(s)"
              counter
              clearable
              v-model="report.device"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Início"
              outlined
              type="datetime-local"
              clearable
              v-model="report.start"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Final"
              outlined
              type="datetime-local"
              clearable
              v-model="report.end"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="loadReport">Buscar Relátorio</v-btn>
      </v-card-actions> -->
      <v-card-actions>
        <!-- excell -->
        <v-btn color="success" v-show="positions.length" @click="exportExcell">
          <v-icon small class="mr-1">mdi-microsoft-excel</v-icon>
          Gerar excell
        </v-btn>
        <!-- pdf -->
        <!-- <v-btn color="success" v-show="positions.length" @click="exportPdf">
          <v-icon small class="mr-1">mdi-file-pdf-box</v-icon>
          Gerar PDF
        </v-btn> -->
        <v-spacer></v-spacer>
        <!-- buscar relatório -->
        <v-btn color="success" @click="loadReport">Buscar Relátorio</v-btn>
      </v-card-actions>
    </v-card>
    <!-- card resumo -->
    <v-card class="mt-2">
      <v-card-subtitle>Resumo</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">Distancia Percorrida</th>
                  <th class="text-left">Velocidade Média</th>
                  <th class="text-left">Velocidade Máxima</th>
                  <th class="text-left">Tempo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ (resume.distance / 1000).toFixed(2) }} Km</td>
                  <td>{{ (resume.averageSpeed * 1.852).toFixed(1) }} Km/h</td>
                  <td>{{ (resume.maxSpeed * 1.852).toFixed(1) }} Km/h</td>
                  <td>{{ resume.difTime }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- card tabela -->
    <v-card class="mt-2">
      <v-card-subtitle>Posições: {{ positions.length }}</v-card-subtitle>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">ID</th>
            <th class="text-left">Endereço</th>
            <th class="text-left">Velocidade</th>
            <th class="text-left">Coordenadas</th>
            <th class="text-left">Curso / Altitude</th>
            <th class="text-left">Data Equip / Serv</th>
            <!-- <th class="text-left">Data Serv</th> -->
            <th class="text-left">Atributos</th>
            <th class="text-left">Rede</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in positions" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.address }}</td>
            <td>{{ (item.speed * 1.825).toFixed(2) }} Km/h</td>
            <td>
              Lat:{{ item.latitude }} <v-divider></v-divider> Lon:{{
                item.longitude
              }}
              <v-btn @click="showPosition(index)">Ver no Mapa</v-btn>
            </td>
            <td>
              {{ item.course.toFixed(2) }}º <v-divider></v-divider>
              {{ item.altitude.toFixed(2) }}Mts
            </td>
            <!-- <td>{{ item.altitude.toFixed(2) }}Mts</td> -->
            <td>
              {{ item.fixTime ? new Date(item.fixTime).toLocaleString() : "" }}
              <v-divider></v-divider>
              {{
                item.serverTime
                  ? new Date(item.serverTime).toLocaleString()
                  : ""
              }}
            </td>
            <!-- <td>
              {{
                item.createdAt ? new Date(item.serverTime).toLocaleString() : ""
              }}
            </td> -->
            <td>
              <!-- <v-chip
                class="ma-1"
                v-for="i in explodeJsonArr(item.attributes)"
                :key="i[0] + item.id"
              >
                <b>{{ i[0] }}</b
                >:
                {{ i[1] }}
              </v-chip> -->
              {{ JSON.stringify(item.attributes) }}
            </td>
            <td>
              <!-- <v-chip
                v-for="i in explodeJsonArr(item.network)"
                :key="i[0] + item.id"
              >
                <b>{{ i[0] }}</b
                >:
                {{ i[1] }}
              </v-chip> -->
              {{ JSON.stringify(item.network) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <!-- dialog Mapa -->
    <!-- <v-btn color="primary" @click="showPosition(posTest)"> Abrir Mapa </v-btn> -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Mapa</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div
              id="mapContainer"
              ref="ctn"
              class="d-flex align-self-stretch"
              :style="`height: 50vh; z-index:3`"
            ></div>
          </v-container>
          <div>
            <v-row class="mx-1">
              <v-col>
                <v-chip> <b>Endereço:</b> {{ pos2show.address }} </v-chip>
              </v-col>
            </v-row>
            <v-row class="mx-1">
              <v-col>
                <v-chip>
                  <b>Velocidade: </b>
                  {{ (pos2show.speed * 1.825).toFixed(2) }} Km/h
                </v-chip>
              </v-col>
              <v-col>
                <v-chip>
                  <b>Data: </b>
                  {{ new Date(pos2show.fixTime).toLocaleString() }}
                </v-chip>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- card route -->
    <v-card class="mt-2">
      <v-card-title
        >Rota <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="showRoute(positions)">
          Mostrar Rota
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div
          id="mapContainerRoute"
          ref="ctn"
          class="d-flex align-self-stretch"
          :style="`height: 50vh; z-index:3`"
        ></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dateISO2InputDateTime } from "../utils/date";
import { differenceDate } from "@/utils/dateDiff.js";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Icon } from "leaflet";
import { generate } from "@/utils/excell.js";
import { orderReport } from "@/utils/pdf.js";
import { rotateMarker } from "@/utils/rotateMarker.js";
export default {
  name: "RelatorioOrdem",
  components: {},
  data: () => ({
    report: {
      device: null,
      start: null,
      end: null,
      order: null,
    },
    devices: [],
    devicesSelect: [],
    positions: [],
    fmtDate: dateISO2InputDateTime,
    resume: {
      distance: 0,
      averageSpeed: 0,
      maxSpeed: 0,
      difTime: 0,
    },
    differenceDate,
    dialog: false,
    map: null,
    marker: null,
    posTest: {
      latitude: 0,
      longitude: -51.1544517,
      address:
        "Rua Rincão, Rincão de Ilhéus, Rio Grande do Sul, Brasil, 93351-000",
      speed: 4.874218102407764,
      fixTime: "2023-03-03T10:02:37.000Z",
    },
    pos2show: {
      latitude: 0,
      longitude: 0,
      address: "",
      speed: 0,
      fixTime: "",
    },
    mapRoute: null,
    markers: [],
    icon: null,
  }),
  methods: {
    loadReport() {
      this.$http.post("ocUsuario/route", this.report).then((resp) => {
        this.positions = resp.data.reverse();
      });
      this.$http.post("ocUsuario/resume", this.report).then((resp) => {
        this.resume = resp.data;
        this.resume.difTime = this.differenceDate(
          this.report.start,
          this.report.end,
          true
        );
      });
    },
    getBOtoUsersSelect() {
      let sel = this.getBOtoUsers.map((e) => {
        let carregada = e.loaded ? "Carregada" : "Descarregada";
        let finalizada = e.finished ? "Finalizada" : "Em Andamento";
        return {
          text: `${e.id} - ${e.uniqueId} - ${carregada} - ${finalizada}`,
          value: e.id,
        };
      });
      return sel.reverse();
    },
    getDevices() {
      this.$http("dispositivo").then((resp) => {
        this.devices = resp.data;
        this.devicesSelect = this.devices.map((e) => {
          return {
            text: `${e.id} - ${e.identificador} - ${e.name}`,
            value: e.identificador,
          };
        });
      });
    },
    getPositions() {
      this.$http("position").then((resp) => {
        this.positions = resp.data;
      });
    },
    getParamsByOrder(id) {
      let botu = this.getBOtoUsers.find((e) => e.id == id);
      if (botu) {
        this.report.device = botu.uniqueId;
        this.report.start = this.fmtDate(botu.loadedDate);
        this.report.end = this.fmtDate(botu.finishedDate);
        this.report.BOToUser = botu.id;
      }
    },
    explodeJson(json) {
      let str = "";
      for (const key in json) {
        if (Object.hasOwnProperty.call(json, key)) {
          // str += `<v-chip><b>${key}:</b> ${json[key]}</v-btn></v-chip>`;
          // str += `<v-btn><b>${key}:</b> ${json[key]}</v-btn>`;
          str += ` <div><b>${key}:</b> ${json[key]}</div>`;
        }
      }
      return str;
    },
    explodeJsonArr(json) {
      let arr = [];
      for (const key in json) {
        if (Object.hasOwnProperty.call(json, key)) {
          arr.push([key, json[key]]);
        }
      }
      return arr;
    },
    initMap() {
      if (this.map != null) {
        return;
      }
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      this.map = L.map("mapContainer").setView(
        [-14.337566608887226, -54.93164062500001],
        4
      );
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" + this.$store.getters.getMapBoxKey,
        {
          maxZoom: 18,
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: "mapbox/streets-v11",
          tileSize: 512,
          zoomOffset: -1,
        }
      ).addTo(this.map);
      rotateMarker(L);
      if(!this.icon){
        this.icon = L.icon({ iconUrl: "/icons/marker.png",  iconSize: [18, 36], iconAnchor: [9, 18] });
      }
    },
    showPosition(index) {
      this.pos2show = this.positions[index];
      let position = this.positions[index];
      this.dialog = true;
      // se o mapa ainda não foi inicializado, aguarda 1 segundo e tenta novamente
      if (this.map == null) {
        setTimeout(() => {
          this.showPosition(index);
        }, 1000);
        return;
      }
      // se o marker não for nulo, remove o marker do mapa
      if (this.marker != null) {
        this.map.removeLayer(this.marker);
      }
      // adiciona o marker no mapa
      this.marker = L.marker([position.latitude, position.longitude], {
        rotationAngle: position.course,
        icon: this.icon,
      })
        .bindTooltip(
          `<table >                
                <tr>
                    <td><b>Endereço:</b></td>
                    <td style="text-align: center;">
                      ${position.address}
                    </td>
                </tr>
                <tr>
                    <td>Velocidade:</td>
                    <td style="text-align: center;">
                      ${(position.speed * 1.825).toFixed(1)} Km/h
                    </td>
                </tr>
                <tr>
                    <td>Última Posição:</td>
                    <td style="text-align: center;">
                      ${new Date(position.fixTime).toLocaleString()}
                    </td>
                </tr>
            </table>
            `,
          {
            direction: "top",
            className: "custom-tooltip",
          }
        )
        .addTo(this.map);

      this.map.setView([position.latitude, position.longitude], 16);
    },
    initMapRoute() {
      if (this.mapRoute != null) {
        return;
      }
      delete Icon.Default.prototype._getIconUrl;
      Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
      });
      this.mapRoute = L.map("mapContainerRoute").setView(
        [-14.337566608887226, -54.93164062500001],
        4
      );
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=" + this.$store.getters.getMapBoxKey,
        {
          maxZoom: 18,
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: "mapbox/streets-v11",
          tileSize: 512,
          zoomOffset: -1,
        }
      ).addTo(this.mapRoute);
      rotateMarker(L);
      if(!this.icon){
        this.icon = L.icon({ iconUrl: "/icons/marker.png",  iconSize: [18, 36], iconAnchor: [9, 18] });
      }
    },
    showRoute(route) {
      // se o mapa ainda não foi inicializado, aguarda 1 segundo e tenta novamente
      if (this.mapRoute == null) {
        this.initMapRoute();
        setTimeout(() => {
          this.showRoute(route);
        }, 1000);
        return;
      }
      let cords = [];
      if (!route.length) {
        return;
      }

      this.markers.forEach((e) => {
        e.remove(this.map);
      });

      // if (this.polyline != null) {
      //   this.polyline.remove(this.map);
      // }

      this.markers = [];

      route.forEach((e) => {
        let b = L.marker([e.latitude, e.longitude],{
        rotationAngle: e.course,
        icon: this.icon,
      })
          .bindTooltip(
            `<table>                
                <tr>
                    <td>Endereço:</td>
                    <td style="text-align: center;">
                      ${e.address}
                    </td>
                </tr>
                <tr>
                    <td>Velocidade:</td>
                    <td style="text-align: center;">
                      ${(e.speed * 1.825).toFixed(1)} Km/h
                    </td>
                </tr>
                <tr>
                    <td>Última Posição:</td>
                    <td style="text-align: center;">
                      ${new Date(e.fixTime).toLocaleString()}
                    </td>
                </tr>
            </table>
            `
          )
          .addTo(this.mapRoute);

        cords.push([e.latitude, e.longitude]);

        this.markers.push(b);
      });

      if (cords.length) {
        // this.polyline = L.polyline(cords).addTo(this.mapRoute);
        this.mapRoute.fitBounds(cords);
      }
    },
    generateRows() {
      let rows = [...this.positions];

      rows.forEach((item) => {
        item.speed = (item.speed * 1.825).toFixed(2) || "";
        item.serverTime = item.serverTime
          ? new Date(item.serverTime).toLocaleString()
          : "";
        item.fixTime = item.fixTime
          ? new Date(item.fixTime).toLocaleString()
          : "";
        item.distance = item.attributes.distance
          ? item.attributes.distance
          : "";
        item.event = item.attributes.event ? item.attributes.event : "";
        item.attr = item.attributes
          ? JSON.stringify(item.attributes, null, 2)
          : "";
      });
      return rows;
    },
    exportExcell() {
      let columns = [
        { header: "ID", key: "id", width: 10 },
        { header: "Endereço", key: "address", width: 32 },
        { header: "Latitude", key: "latitude", width: 20 },
        { header: "Longitude", key: "longitude", width: 20 },
        { header: "Vel. KM/h", key: "speed", width: 10 },
        { header: "Curso", key: "course", width: 10 },
        { header: "Hora Dispositivo", key: "fixTime", width: 20 },
        { header: "Hora Servidor", key: "serverTime", width: 20 },
        { header: "Distancia", key: "distance", width: 10 },
        { header: "Evento", key: "event", width: 10 },
        { header: "Atributos", key: "attr", width: 32 },
      ];
      // gera as linhas para o excel
      let rows = this.generateRows(this.positions);
      // nome da planilha
      let nameSheet = "STV Relatório de posiçoes";
      // corpo do excel
      let excelBody = [{ columns, rows, nameSheet }];
      // gera o excel e faz o download
      generate(excelBody).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Relatório de posições.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    exportPdf() {
      // gera as linhas para o excel
      let rows = this.generateRows(this.getBOReport);
      // nome da planilha
      let title = "STV Relatório ordens usuário";
      // gera o excel e faz o download
      orderReport(rows, title, null, title.replace(" ", "_"));
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.initMap();
        }, 200);
      }
    },
  },
  computed: {
    ...mapGetters([
      "getBOReport",
      "getConstructions",
      "getOrders",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
      "getUserSelect",
      "getBOtoUsers",
      "getItemOrdemById",
      "getOrderById",
      "getUserNameById",
      "getVehiclesSelect",
      "getTraceableById",
      "getUserById",
      "getVehicleById",
      "getBOReportFilter",
      "getOrdemSelect",
    ]),
  },
  mounted() {
    this.$store.dispatch("loadAllBOtoUsers");
    this.getDevices();
    // this.report = this.getBOReportFilter;
  },
};
</script>

<style>
.text-center {
  overflow-wrap: break-word;
  text-align: center;
}
</style>