exports.dateISO2InputDateTime = function (dateISO) {
  const twoAlgarisms = (num) => {
    return num < 10 ? `0${num}` : num;
  };
  const fmtDate = (date) => {
    date = new Date(date);
    if (date == "Invalid Date") new Date();
    return `${date.getFullYear()}-${twoAlgarisms(date.getMonth() + 1)}-${twoAlgarisms(date.getDate())}T${twoAlgarisms(date.getHours())}:${twoAlgarisms(date.getMinutes())}:${twoAlgarisms(date.getSeconds())}`;
  };
  return fmtDate(dateISO);
};